import React from 'react'
import Contact from '../../components/Contact/Contact'
import Header from '../../components/Header/Header'
import Hero from '../../components/Hero/Hero'
import Marcas from '../../components/Marcas/Marcas'
import Products from '../../components/Products/Products'
import Footer from '../../components/Footer/Footer'

const Home = () => {
	return (
		<>
			<Header page='home' />
			<Hero />
			<Products />
			<Marcas />
			<Contact />
			<Footer />

			{/* 
			<Servicios />
			<Tendencia />
			<Ideas />
			<FormasPago />
			 */}

		</>
	);
}
 
export default Home;