import React from 'react';
import { Link } from "wouter";
import './header.scss'

import { Link as Scroll } from "react-scroll";
// import { Link, animateScroll as scroll } from "react-scroll";

import logo from '../../images/logoAutopartes.png'

const Header = ({page}) => {
	
	let pagina = page;
	
	return (
		<header>
			<div className="container">
				<nav className="navbar navbar-expand-lg">
					<a className="navbar-brand" href="/#">
						<img src={logo} alt="Zona Color logo"/>
					</a>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						&#9776;
						{/* <span className="navbar-toggler-icon"></span> */}
					</button>

					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav ml-auto">
							<li className={(pagina === 'home') ? 'nav-item active' : 'nav-item'}>
								<Link className="nav-link" to="/#">INICIO</Link>
							</li>
							{/* <li className={(pagina === 'productos') ? 'nav-item active' : 'nav-item'}>
								<Link className="nav-link" href="/Productos">PRODUCTOS</Link>
							</li> */}
							<li className={(pagina === 'nosotros') ? 'nav-item active' : 'nav-item'}>
								<a className="nav-link" target="_blank" rel="noreferrer" href="http://catalogo.autopartesdelsur.com.ar/#/">CATÁLOGO</a>
							</li>
							<li className={(pagina === 'ideas') ? 'nav-item active' : 'nav-item'}>
								<Scroll className="nav-link" to="contacto" spy={true} smooth={true} offset={50} duration={1200} >CONTACTO</Scroll>
							</li>
							{/* <li className={(pagina === 'sucursales') ? 'nav-item active' : 'nav-item'}>
								<a className="nav-link" href="/sucursales">GUÍA DE PINTURERÍAS</a>
							</li> */}
						</ul>
					</div>
				</nav>
			</div>
		</header>	
	);
}
 
export default Header;