import React from 'react'
import './ContactForm.scss'

import loader from '../../images/loader.gif'

const ContactForm = () => {
	return (
		<div className="contactForm">
			<h3>Contactenos</h3>
			<form id="frmContact">
				<div className="form-group">
					<label htmlFor="nombre">Nombre:</label>
					<input type="text" className="form-control" id="nombre" name="nombre" required />
				</div>
				<div className="form-group">
					<label htmlFor="telefono">Teléfono:</label>
					<input type="text" className="form-control" id="telefono" name="telefono" />
				</div>
				<div className="form-group">
					<label htmlFor="email">Email:</label>
					<input type="email" className="form-control" id="email" name="email" required />
				</div>
				<div className="form-group">
					<label htmlFor="email">Mensaje:</label>
					<textarea className="form-control" id="mensaje" name="mensaje" rows="3" required></textarea>
				</div>
				<button id="btnEnviar" type="submit" className="btn btn-primary">Enviar</button>
				<div id="loader-icon">
					<img src={loader} alt="Cargando..."/>
				</div>
				<div id="msj-status"></div>
			</form>

		</div>
	)
}

export default ContactForm
