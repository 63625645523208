import React from 'react'
import './Products.scss'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import producto1 from '../../images/products/producto-01.jpg'
import producto2 from '../../images/products/producto-02.jpg'
import producto3 from '../../images/products/producto-03.jpg'
import producto4 from '../../images/products/producto-04.jpg'
import producto5 from '../../images/products/producto-05.jpg'
import producto6 from '../../images/products/producto-06.jpg'
import producto7 from '../../images/products/producto-07.jpg'
import producto8 from '../../images/products/producto-08.jpg'
import producto9 from '../../images/products/producto-09.jpg'
import producto10 from '../../images/products/producto-10.jpg'
import producto11 from '../../images/products/producto-11.jpg'
import producto12 from '../../images/products/producto-12.jpg'
import producto13 from '../../images/products/producto-13.jpg'
import producto14 from '../../images/products/producto-14.jpeg'
import producto15 from '../../images/products/producto-15.jpeg'
import producto16 from '../../images/products/producto-16.jpeg'
import producto17 from '../../images/products/producto-17.jpeg'
import producto18 from '../../images/products/producto-18.jpeg'
import producto19 from '../../images/products/producto-19.jpeg'
import producto20 from '../../images/products/producto-20.jpeg'
import producto21 from '../../images/products/producto-21.jpeg'
import producto22 from '../../images/products/producto-22.jpeg'
import producto23 from '../../images/products/producto-23.jpeg'
import producto24 from '../../images/products/producto-24.jpeg'
import producto25 from '../../images/products/producto-25.jpeg'
import producto26 from '../../images/products/producto-26.jpeg'


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

const Products = () => {
	return (
		<div className="container">
			<div className="row">
				<div className="col-12 mb-4">
					<Carousel
						responsive={responsive}
						containerClass="carousel-productos"
						showDots={true}
						infinite={true}
						autoPlay={true}
						transitionDuration={500}
						autoPlaySpeed={4500}
						removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
					>
						<div><img src={producto1} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto2} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto3} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto4} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto5} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto6} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto7} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto8} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto9} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto10} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto11} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto12} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto13} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto14} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto15} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto16} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto17} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto18} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto19} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto20} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto21} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto22} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto23} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto24} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto25} alt="Productos Autopartes del Sur"/></div>
						<div><img src={producto26} alt="Productos Autopartes del Sur"/></div>

					</Carousel>
				</div>
			</div>
		</div>
	)
}

export default Products
