import React from 'react'
import ContactForm from '../ContactForm/ContactForm'
import MapContainer from '../MapContainer/MapContainer'

import './Contact.scss'

const Contact = () => {
	return (
		<section id="contacto" className="contact mt-4">
			<MapContainer />
			<ContactForm />
		</section>
	)
}

export default Contact
