import React from 'react'
import './Hero.scss'

import motor from '../../images/home/motor-home.png'

const Hero = () => {
	return (
		<div className="hero-w">
			<div className="hero">
				<div className="container">
					<div className="row">
						<div className="col-sm-6">
								<h1>Distribuidor Mayorista de Partes de Motor</h1>
								<p>Una joven empresa abocada a llevar soluciones, con excelencia en logística, priorizando la buena atención y el asesoramiento técnico.</p>
								<p>Creemos siempre que un cliente conforme con nuestro servicio es una persona que recomienda para bien nuestra empresa.</p>
								<p>Solo nos orientamos al servicio y a la permanente disponibilidad de productos para entrega inmediata.</p>	
						</div>
						<div className="col-sm-6">
							
						</div>	
					</div>
				</div>
			</div>
			<img src={motor} alt="motor home" />
		</div>
	)
}

export default Hero
