import React from 'react'
import Contact from '../../components/Contact/Contact'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

import './Productos.scss'

import producto1 from '../../images/products/producto-01.jpg'
import producto2 from '../../images/products/producto-02.jpg'
import producto3 from '../../images/products/producto-03.jpg'
import producto4 from '../../images/products/producto-04.jpg'
import producto5 from '../../images/products/producto-05.jpg'
import producto6 from '../../images/products/producto-06.jpg'
import producto7 from '../../images/products/producto-07.jpg'
import producto8 from '../../images/products/producto-08.jpg'
import producto9 from '../../images/products/producto-09.jpg'
import producto10 from '../../images/products/producto-10.jpg'
import producto11 from '../../images/products/producto-11.jpg'
import producto12 from '../../images/products/producto-12.jpg'
import producto13 from '../../images/products/producto-13.jpg'


const Home = () => {
	return (
		<>
			<Header page='productos' />
			
			<div className="container">
				<div className="row">
					<div className="col-12 mt-5">
						<h2 className="title">Nuestros <strong>Productos</strong></h2>
					</div>
					<div className="col-12 my-4">
						<div className="grid__galerry">
							<div><img className="img-fluid" src={producto1} alt="Productos Autopartes del Sur"/></div>
							<div><img className="img-fluid" src={producto2} alt="Productos Autopartes del Sur"/></div>
							<div><img className="img-fluid" src={producto3} alt="Productos Autopartes del Sur"/></div>
							<div><img className="img-fluid" src={producto4} alt="Productos Autopartes del Sur"/></div>
							<div><img className="img-fluid" src={producto5} alt="Productos Autopartes del Sur"/></div>
							<div><img className="img-fluid" src={producto6} alt="Productos Autopartes del Sur"/></div>
							<div><img className="img-fluid" src={producto7} alt="Productos Autopartes del Sur"/></div>
							<div><img className="img-fluid" src={producto8} alt="Productos Autopartes del Sur"/></div>
							<div><img className="img-fluid" src={producto10} alt="Productos Autopartes del Sur"/></div>
							<div><img className="img-fluid" src={producto9} alt="Productos Autopartes del Sur"/></div>
							<div><img className="img-fluid" src={producto11} alt="Productos Autopartes del Sur"/></div>
							<div><img className="img-fluid" src={producto12} alt="Productos Autopartes del Sur"/></div>
							<div><img className="img-fluid" src={producto13} alt="Productos Autopartes del Sur"/></div>
						</div>
					</div>
				</div>
			</div>

			<Contact />
			<Footer />


		</>
	);
}
 
export default Home;