import React from 'react'
import './Marcas.scss'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import taranto from '../../images/marcas/taranto.jpg'
import bbb from '../../images/marcas/bbb.jpg'
import illinois from '../../images/marcas/illinois.jpg'
import gh from '../../images/marcas/gh.jpg'
import tensa from '../../images/marcas/tensa.jpg'
import sachs from '../../images/marcas/sachs.jpg'
import elring from '../../images/marcas/elring.jpg'
import bh from '../../images/marcas/bh.jpg'
import powerengine from '../../images/marcas/powerengine.jpg'
import nubo from '../../images/marcas/nubo.jpg'
import fadecya from '../../images/marcas/fadecya.jpg'
import centrinel from '../../images/marcas/centrinel.jpg'
import vmg from '../../images/marcas/vmg.jpg'
import federalmogul from '../../images/marcas/federalmogul.jpg'
import champion from '../../images/marcas/champion.jpg'
import goetze from '../../images/marcas/goetze.jpg'
import glyco from '../../images/marcas/glyco.jpg'
import ae from '../../images/marcas/ae.jpg'
import fpDiesel from '../../images/marcas/fp-diesel.jpg'
import sgb from '../../images/marcas/sgb.jpg'
import ngk from '../../images/marcas/ngk.jpg'
import ciccarelli from '../../images/marcas/ciccarelli.jpg'
import moog from '../../images/marcas/moog.jpg'
import eking from '../../images/marcas/eking.jpg'


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 8
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

const Marcas = () => {
	return (
		<div className="container">
			<div className="row">
				<div className="col-12 mb-4">
					<Carousel
						responsive={responsive}
						showDots={true}
						infinite={true}
  					autoPlay={true}
						autoPlaySpeed={1500}
						removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
					>
						<div><img src={taranto} alt="taranto" /></div>
						<div><img src={bbb} alt="bbb" /></div>
						<div><img src={illinois} alt="illinois" /></div>
						<div><img src={gh} alt="gh" /></div>
						<div><img src={tensa} alt="tensa" /></div>
						<div><img src={sachs} alt="sachs" /></div>
						<div><img src={elring} alt="elring" /></div>
						<div><img src={bh} alt="bh" /></div>
						<div><img src={powerengine} alt="power engine" /></div>
						<div><img src={nubo} alt="nubo" /></div>
						<div><img src={fadecya} alt="fadecya" /></div>
						<div><img src={centrinel} alt="centrinel" /></div>
						<div><img src={vmg} alt="vmg" /></div>
						<div><img src={federalmogul} alt="federal mogul" /></div>
						<div><img src={champion} alt="champion" /></div>
						<div><img src={goetze} alt="goetze" /></div>
						<div><img src={glyco} alt="glyco" /></div>
						<div><img src={ae} alt="ae" /></div>
						<div><img src={fpDiesel} alt="fpDiesel" /></div>
						<div><img src={sgb} alt="sgb" /></div>
						<div><img src={ngk} alt="ngk" /></div>
						<div><img src={ciccarelli} alt="ciccarelli" /></div>
						<div><img src={moog} alt="moog" /></div>
						<div><img src={eking} alt="eking" /></div>
					</Carousel>
				</div>
			</div>
		</div>
	)
}

export default Marcas
