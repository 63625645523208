import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
// import PropTypes from 'prop-types';

import './styles.scss';

const mapStyles = {
	width: '100%',
	position: 'relative',
	minHeight: '38rem',
};

export class MapContainer extends Component {
	
	constructor(props) {
	  super(props);
	
	}
 
	render() {
	  return (
		  <Map
				google={this.props.google}
				zoom={15}
				style={mapStyles}
				styles={[
						{
								featureType: 'all',
								stylers: [{ saturation: -100 }],
						},
				]}
				initialCenter={{ lat: -38.728565, lng: -62.283714 }} // -38.728565, -62.273714
				>
				<Marker
					icon= 'https://darsur.com.ar/images/marker-google-map.png'
					position={{ lat: -38.728561, lng: -62.268389 }} />
		  </Map>
	  );
	}
}

MapContainer.defaultProps = {
	// The style is copy from https://snazzymaps.com/style/2/midnight-commander
	mapStyles: [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"color":"#000000"},{"lightness":13}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#144b53"},{"lightness":14},{"weight":1.4}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#08304b"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#0c4152"},{"lightness":5}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#0b434f"},{"lightness":25}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#000000"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#0b3d51"},{"lightness":16}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#000000"}]},{"featureType":"transit","elementType":"all","stylers":[{"color":"#146474"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#021019"}]}]
}


export default GoogleApiWrapper({
  apiKey: 'AIzaSyCIvwWqr9Kc9I0Smett1GlwLQPtEWASMMM'
})(MapContainer);