import React from 'react'
// import iconWhatsapp from '../whatsapp'
import './Footer.scss'

import logoParadigma from '../../images/logoParadigma.png'


const Footer = () => {
	return (
		<footer>
			<div className="container">
				<div className="row">
					<div className="col-md-4">
						<p>
							Villarino e Italia, Bahía Blanca <br />
							Tel. 0291 4547010 <br />
							Tel. ‎+54 9 291 576-3137 <br />
							<img src="https://www.flaticon.com/svg/static/icons/svg/733/733585.svg" alt="" className="icon"/>
							<a href="https://api.whatsapp.com/send?phone=5492914497131" target="_blank" rel="noreferrer">+54 9 2914 49-7131</a>
						</p>
					</div>
					<div className="col-md-4">
						<p>
							info@autopartesdelsur.com.ar <br />
							pedidos@autopartesdelsur.com.ar
						</p>
					</div>
					<div className="col-md-4">
						<p>
							© 2020 Diseño y Desarrollo:<br />
							<img src={logoParadigma} alt="Paradigma del Sur s.a." />
						</p>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer
