import React from 'react'
import './App.scss'
import { Route } from "wouter";
import Home from './pages/Home/Home'
import Productos from './pages/Productos/Productos';
// import Ideas from './pages/Ideas/Ideas';
// import Sucursales from './pages/Sucursales/Sucursales';
// import Nosotros from './pages/Nosotros/Nosotros';

const App = () => {
  return (
    <>
      <Route path="/" component={Home} />
      <Route path="/productos" component={Productos} />
      {/* <Route path="/ideas" component={Ideas} />
      <Route path="/sucursales" component={Sucursales} />
      <Route path="/nosotros" component={Nosotros} /> */}
    </>
  )
}

export default App
